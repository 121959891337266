import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FamilyDayPage from "./Pages/FamilyDayPage"
import SuccessPage from './Pages/successpage';
import RSVPCount from './Pages/rsvpcount';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './FamilyDay.css'

function App() {
  return (
    <div className='container'>
      <ToastContainer closeButton={false} position="bottom-right" />
      <Router>
        <Routes>
          <Route path='/' element={<FamilyDayPage />} />
          <Route path='/rsvp' element={<FamilyDayPage />} />
          <Route path='/success' element={<SuccessPage />} />
          <Route path='/count' element={<RSVPCount />} />
        </Routes>
      </Router>
      {/* <div ><label style={{ marginLeft: "100px", fontWeight: 'normal' }}>© Sonance</label>&nbsp;{new Date().getFullYear()}</div> */}

    </div>
  );
}

export default App;
