import React, { useState } from "react";
import {
  Container, Row, Col, Button, Form, FormGroup, Input, Label,
} from "reactstrap";
import { isEmpty } from "../lib/util";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

const FamilyDayPage = () => {
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_SERVER_URL;

  // State management for form data
  const [formData, setFormData] = useState({
    employeeName: "",
    isAttending: "",
    adultGuestName: "",
    childGuestName: "",
    childGuestAge: "",
    adultGuests: [],
    childGuests: [],
  });

  // Input change handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAdultGuestNameChange = (value) => {
    setFormData({ ...formData, adultGuestName: value });
  };

  const handleChildGuestNameChange = (value) => {
    setFormData({ ...formData, childGuestName: value });
  };

  const handleChildGuestAgeChange = (value) => {
    setFormData({ ...formData, childGuestAge: value });
  };

  // Guest management functions
  const addAdultGuest = () => {
    if (formData.adultGuestName.trim()) {
      setFormData({
        ...formData,
        adultGuests: [...formData.adultGuests, { name: formData.adultGuestName.trim() }],
        adultGuestName: "",
      });
    }
  };

  const addChildGuest = () => {
    if (formData.childGuestName.trim() && formData.childGuestAge) {
      setFormData({
        ...formData,
        childGuests: [...formData.childGuests, { name: formData.childGuestName.trim(), age: formData.childGuestAge }],
        childGuestName: "",
        childGuestAge: "",
      });
    }
  };

  const removeAdultGuest = (index) => {
    const newAdultGuests = formData.adultGuests.filter((_, i) => i !== index);
    setFormData({ ...formData, adultGuests: newAdultGuests });
  };

  const removeChildGuest = (index) => {
    const newChildGuests = formData.childGuests.filter((_, i) => i !== index);
    setFormData({ ...formData, childGuests: newChildGuests });
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmpty(formData.employeeName) || isEmpty(formData.isAttending)) {
      toast.error("Please fill in all required fields");
      return;
    }

    try {
      const allGuests = [
        ...formData.adultGuests.map((guest) => guest.name),
        ...formData.childGuests.map((guest) => ` ${guest.name} - ${guest.age}`),
      ];

      const payload = {
        employeeName: formData.employeeName,
        isAttending: formData.isAttending,
        guests: allGuests,
      };
      console.log(payload);
      await axios.post(`${api_url}RSVP`, payload);
      toast.success("RSVP saved successfully.");
      navigate("/success");
    } catch (error) {
      console.error("Error saving the RSVP", error);
      toast.error("Error saving the RSVP.");
    }
  };

  // Render RSVP form
  return (
    <Container className="rsvp-container">
      {/* Event banner image */}
      <Row>
        <Col>
          <img
            src={require("../images/Family Day 2024 - RSVP Link-2.png")}
            alt="Beyond Sound Fest"
            className="event-image"
          />
        </Col>
      </Row>

      <Form onSubmit={handleSubmit}>
        {/* Employee name input */}
        <Row>
          <Col>
            <FormGroup>
              <Input
                type="text"
                name="employeeName"
                value={formData.employeeName}
                onChange={handleInputChange}
                placeholder="Please enter your first and last name"
                required
              />
            </FormGroup>
          </Col>
        </Row>

        {/* Attendance selection */}
        <Row>
          <Col>
            <FormGroup tag="fieldset">
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="isAttending"
                    value="yes"
                    checked={formData.isAttending === "yes"}
                    onChange={handleInputChange}
                  />{" "}
                  Attending
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="isAttending"
                    value="no"
                    checked={formData.isAttending === "no"}
                    onChange={handleInputChange}
                  />{" "}
                  Not Attending
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Guest inputs and lists (only shown if attending) */}
        {formData.isAttending === "yes" && (
          <Row>
            <Col>
              {/* Adult guest input */}
              <Row className="mb-3">
                <Col xs={9}>
                  <Input
                    type="text"
                    value={formData.adultGuestName}
                    onChange={(e) => handleAdultGuestNameChange(e.target.value)}
                    placeholder="Adult Guest Name"
                  />
                </Col>
                <Col xs={3}>
                  <Button
                    onClick={addAdultGuest}
                    color="secondary"
                    block
                    className="add-guest-btn"
                  >
                    <span className="d-none d-sm-inline">Add Adult Guest</span>
                  </Button>
                </Col>
              </Row>

              {/* Child guest input */}
              <Row className="mb-3">
                <Col xs={6}>
                  <Input
                    type="text"
                    value={formData.childGuestName}
                    onChange={(e) => handleChildGuestNameChange(e.target.value)}
                    placeholder="Child Guest Name"
                  />
                </Col>
                <Col xs={3}>
                  <Input
                    type="number"
                    value={formData.childGuestAge}
                    onChange={(e) => handleChildGuestAgeChange(e.target.value)}
                    placeholder="Age"
                    min="0"
                    max="17"
                    style={{ height: "45px" }}
                  />
                </Col>
                <Col xs={3}>
                  <Button
                    onClick={addChildGuest}
                    color="secondary"
                    block
                    className="add-child-btn"
                  >
                    <span className="d-none d-sm-inline">Add Child Guest</span>
                  </Button>
                </Col>
              </Row>

              {/* Guest lists */}
              <Row>
                {/* Adult guest list */}
                <Col md={6}>
                  <h5 className="guest-list-heading">Adult Guests</h5>
                  {formData.adultGuests.map((guest, index) => (
                    <div key={index} className="guest-item">
                      <span className="guest-name">{guest.name}</span>
                      <Button
                        onClick={() => removeAdultGuest(index)}
                        color="danger"
                        size="sm"
                        className="btn-sm btn-remove"
                      >
                        Remove Guest
                      </Button>
                    </div>
                  ))}
                </Col>
                {/* Child guest list */}
                <Col md={6}>
                  <h5 className="guest-list-heading">Child Guests</h5>
                  {formData.childGuests.map((guest, index) => (
                    <div key={index} className="guest-item">
                      <span className="guest-name">
                        {guest.name} - Age: {guest.age}
                      </span>
                      <Button
                        onClick={() => removeChildGuest(index)}
                        color="danger"
                        size="sm"
                        className="btn-sm btn-remove"
                      >
                        Remove Guest
                      </Button>
                    </div>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {/* Submit button */}
        <Row>
          <Col>
            <Button type="submit" color="primary" className="submit-rsvp-button">
              Submit RSVP
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default FamilyDayPage;