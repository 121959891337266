import React, { useState, useEffect } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';

const RSVPCount = () => {

    const api_url = process.env.REACT_APP_SERVER_URL;
    const [show, setShow] = useState(false);
    const [responses, setResponses] = useState([]);
    const columns  = [
        
        { field: "employeeName", headerName: "Employee Name", width: 300 },
        { field: "isAttending", headerName: "Attending", width: 200,
            valueGetter: (params) => {
            if (params.value) {
              return "Yes";
            } 
            return "No"
        }},
        { field: "mealSelection", headerName: "Meal Selection", width: 300 },
        { field: "allergy", headerName: "Notes(Allergy)", width: 300 },
        { field: "createdDateTime", headerName: "Date", width: 200 }
      ];


    const fetchRSVPS = async () => {
        
        const response = await axios(`${api_url}rsvp`);
       
        if (response) {
             setResponses(response.data);
             console.log(response.data);
             setShow(true);
        }
            
    }

    useEffect(() => {
     
        fetchRSVPS();
        console.log("responses",responses);
    }, []);
     
   
    return (

        
        <>
            {
                show && <div>
                    
                    <DataGrid rows={responses} columns={columns} />

                </div>
            }
       
       </>
    );
}

export default RSVPCount;